<template>
<div class="page-content w-100">
  <!-- start heading -->
  <div class="row">
    <div class="col-sm-12">
      <p class="text-center page-heading">Knowledge base</p>
    </div>
  </div>
  <!-- end heading -->
  <div class="row px-2" style="margin-top:20px">
    <div class="col-md-12 col-xs-12">
      <ul class="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link text-color active text-20 font-weight-bold" id="pills-standardTreatment-tab" data-bs-toggle="pill" data-bs-target="#pills-standardTreatment" type="button" role="tab" aria-controls="pills-standardTreatment" aria-selected="false">Standard treatment guidelines</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link text-color text-20 font-weight-bold" id="pills-infoDisorder-tab" data-bs-toggle="pill" data-bs-target="#pills-infoDisorder" type="button" role="tab" aria-controls="pills-infoDisorder" aria-selected="false">Info on disorder</button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-standardTreatment" role="tabpanel" aria-labelledby="pills-standardTreatment-tab">
         
        </div>
        <div class="tab-pane fade" id="pills-infoDisorder" role="tabpanel" aria-labelledby="pills-infoDisorder-tab">
        
        </div>
      </div>
    </div>
  </div>
</div>
</template>

